import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
	<Layout>
		<SEO title="404: Not found" />
		<div
			className="bg-light pt-5 text-center"
			style={{ height: "80vh", position: "relative" }}
		>
			<div
				style={{
					position: "absolute",
					top: "50%",
					left: "0",
					right: "0",
					transform: "perspective(1px) translateY(-50%)",
				}}
			>
				<div className="container">
					<h1>Not Found</h1>
					<p>This page does not exist.</p>

					<Link className="btn btn-primary" role="button" to="/">
						Take Me Home
					</Link>
				</div>
			</div>
		</div>
	</Layout>
)

export default NotFoundPage
